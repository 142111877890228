import React, { useEffect, useState } from 'react';
import { CommonBackContent } from '../Layouts/CommonBackContent';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { commonImgPath } from '@/shared/app-common';

const ContentDiv = styled.div`
  overflow: auto;
  background-image: url(${() => `${commonImgPath}/home-top-bg.webp`});
  background-repeat: no-repeat;
  background-position: top right; 
  background-size: auto auto; 

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const LoginStateInfo = dynamic(() => import('./LoginStateInfo'), { ssr: true });
const DetailShowContent = dynamic(() => import('./DetailShowContent'), { ssr: true });
const ChooseInstantKnowContent = dynamic(() => import('./ChooseInstantKnowContent'), { ssr: true });
const UseShowContent = dynamic(() => import('./UseShowContent'), { ssr: true });
const CustomersTalkUsContent = dynamic(() => import('./CustomersTalkUsContent'), { ssr: true });
const RecommendedArticleContent = dynamic(() => import('./RecommendedArticleContent'), {
  ssr: true,
});
const RegisterContent = dynamic(() => import('./RegisterContent'), { ssr: true });

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const router = useRouter();
  const topInfo = React.useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const pageType = data.pageType;


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        setIsIntersecting(entry.isIntersecting)
      });
    });

    if (topInfo.current) {
      observer.observe(topInfo.current);
    }

    return () => {
      if (topInfo.current) {
        observer.unobserve(topInfo.current);
      }
    };
  }, []);

  return (
    <ContentDiv>
      <MemoCommonBackContent style={{ background: 'transparent' }}>
        <div ref={topInfo}>
          <LoginStateInfo pageType={pageType} />
        </div>
        <div id='for-what' style={{ scrollMarginTop: '150px' }}>
          <DetailShowContent />
        </div>
        <UseShowContent />
        <div id='features' style={{ scrollMarginTop: '150px' }}>
          <ChooseInstantKnowContent />
        </div>
      </MemoCommonBackContent>

      <div style={{ padding: '1rem' }}>
        {/* {data?.articleList?.length > 0 && <RecommendedArticleContent dataList={data?.articleList} />} */}
        <CustomersTalkUsContent />
      </div>

      <MemoCommonBackContent style={{ background: 'transparent' }}>
        <RegisterContent isIntersecting={isIntersecting} />
      </MemoCommonBackContent>
    </ContentDiv>
  );
};

export default HomeContent;
